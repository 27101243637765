import { useEffect } from 'react'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore.js'

export const useFetchData = ({ takedownData, selectedTab, fetchData }) => {
  const { dateValue, statusValue, setTakedownData } = useStore(takedownStore)

  const currentDateValue =
    dateValue && selectedTab ? dateValue[selectedTab] : null
  const currentStatusValue = statusValue[selectedTab]
  const page = takedownData[selectedTab]?.currentPage
  useEffect(() => {
    //* Check if data for the selected tab is not available
    if (
      !takedownData[selectedTab]?.data ||
      //* Check if the date filter has changed and a valid date range is selected
      (takedownData[selectedTab]?.appliedFilters.date !== currentDateValue &&
        takedownData[selectedTab].data &&
        currentDateValue?.to &&
        currentDateValue?.from) ||
      //* Check if the status filter has changed
      (takedownData[selectedTab]?.appliedFilters.status !==
        currentStatusValue &&
        takedownData[selectedTab].data)
    ) {
      //* Fetch data for the current page
      fetchData(page)
    }
    //* Update the applied filters in the takedown data for the current tab
    setTakedownData(selectedTab, {
      appliedFilters: {
        ...takedownData[selectedTab].appliedFilters,
        date: currentDateValue,
        status: currentStatusValue
      }
    })
  }, [selectedTab, currentDateValue, page, currentStatusValue])
}
