import { useStore } from 'zustand'
import { useNavigate } from 'react-router-dom'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper'
import homeStore from '@/pages/Home/HomeStore'
import { cn } from '@/lib/utils.js'
import CategoriesChart from '../../ProgressCharts/CategoriesChart'
import ContentCard from '@/components/ContentCard/ContentCard'
import { useEffect, useState } from 'react'
import TooltipWrapper from '@/components/common/Wrappers/Tooltip/TooltipWrapper'
import { ScrollArea, ScrollBar } from '@/components/common/ui/scroll-area'
import SocialIcon from '@/components/common/ui/social-icon'

const useWindowResize = ({ setIsLgOrAbove }) => {
  useEffect(() => {
    const handleResize = () => {
      setIsLgOrAbove(window.innerWidth >= 1280)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
}

const Card = ({
  title,
  data,
  name,
  deepfakeLoading = true,
  videosLoading = true,
  socialLoading = true,
  maxItems
}) => {
  const navigate = useNavigate()
  const [isLgOrAbove, setIsLgOrAbove] = useState(window.innerWidth >= 1024)
  useWindowResize({ setIsLgOrAbove })
  const handleNavigation = (id) => {
    navigate(`/watchtower/${id}`)
  }

  const isLoading =
    (name === 'deepfake' && deepfakeLoading) ||
    (name === 'videos' && videosLoading) ||
    (name === 'social' && socialLoading)

  const itemsToShow = isLoading
    ? Array.from({ length: 10 }).map((_, index) => (
        <SkeletonWrapper
          width={'120px'}
          height={'200px'}
          number={1}
          key={index}
          className={'pr-2'}
        />
      ))
    : data?.map((item, index) => (
        <div className='pr-2.5' key={index}>
          <ContentCard
            handleNavigation={() => {
              handleNavigation(
                `${item.takedown_id ? item.takedown_id : item.search_results_id}?takedown=${item.takedown_id ? 'true&selectedTab=media' : false}`
              )
            }}
            item={item}
            name={name}
          />
        </div>
      ))

  const cardWidth = Math.min(data?.length || maxItems, maxItems) * 140
  return (
    <div
      className={cn(
        'flex flex-col px-6 md:px-6 lg:px-3.5 pt-3.5 pb-1.5 text-gray-950 font-medium gap-2'
      )}
      style={
        name !== 'social' && name !== 'videos' && isLgOrAbove
          ? { width: `${cardWidth}px` }
          : {}
      }
    >
      <span className='text-sm font-normal text-text-muted'>{title}</span>
      <ScrollArea className='whitespace-nowrap'>
        <div className='flex justify-between md:justify-start pt-1 mb-4 w-full'>
          {itemsToShow}
        </div>
        <ScrollBar orientation='horizontal' />
      </ScrollArea>
    </div>
  )
}

const ImpersonationsData = ({ data, loading }) => {
  const navigate = useNavigate()
  const handleNavigation = (id) => {
    const encodedId = encodeURIComponent(id)
    navigate(`/watchtower/${encodedId}?type=username_platform`)
  }
  return (
    <div className='flex flex-col h-full w-full border p-4 rounded-lg'>
      <span className='text-text-muted text-sm px-1 pb-2'>
        Top impersonators
      </span>
      <div className={'border-b border-muted'}>
        <div
          className={
            'text-xs text-muted-foreground/80 w-full flex justify-between py-2 px-2 '
          }
        >
          <p>Account</p>
          <p>Followers</p>
        </div>
      </div>
      <div className='w-full overflow-x-auto h-full flex flex-col flex-start gap-1 py-1'>
        {loading ? (
          <SkeletonWrapper width={'100%'} height={'2rem'} number={6} />
        ) : (
          data?.map((item, index) => (
            <div
              key={index}
              className={cn(
                'flex gap-2 py-2 w-full cursor-pointer items-center px-2 hover:bg-muted rounded-md'
              )}
              onClick={() => handleNavigation(item.username_platform)}
            >
              <p className='text-xs text-muted-foreground pr-2 text-center w-4'>
                {index + 1}
              </p>
              <span className='text-gray-950 flex-1 font-medium text-sm flex items-center gap-2'>
                {item?.platform ? (
                  <TooltipWrapper
                    component={<SocialIcon platform={item?.platform} />}
                    text={
                      item?.platform.charAt(0).toUpperCase() +
                      item?.platform.slice(1)
                    }
                    delayDuration={20}
                  />
                ) : null}

                {item.username}
              </span>
              <div className='flex gap-3 items-center'>
                <div className='text-gray-500 text-xs'>
                  {item?.followers?.toLocaleString('en-US')}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

const TrendingSection = ({ loading, searchCounts }) => {
  const {
    deepfakeLoading,
    videosLoading,
    dashboardDeepfakes,
    dashboardVideos,
    dashboardImpersonations,
    impersonationsLoading,
    socialLoading,
    dashboardSocial,
    searchSummaryLoading
  } = useStore(homeStore)

  return (
    <>
      {loading ? (
        <SkeletonWrapper
          width={'100%'}
          height={'17rem'}
          number={2}
          className={'flex gap-4 flex-col'}
        />
      ) : (
        <div className='w-full select-none relative flex flex-col gap-4'>
          <div className='flex flex-col lg:flex-row gap-4'>
            <div className='w-full lg:w-2/4'>
              <ImpersonationsData
                data={dashboardImpersonations?.sort(
                  (a, b) => b.followers - a.followers
                )}
                loading={impersonationsLoading}
              />
            </div>

            <div className='w-full'>
              {searchSummaryLoading ? (
                <div className='flex flex-col h-full w-full border p-4 rounded-lg'>
                  <span className='text-text-muted text-sm px-1 pb-2'>
                    Top Categories
                  </span>
                  <SkeletonWrapper
                    width='100%'
                    height='200px'
                    number={1}
                    className='pt-2'
                  />
                </div>
              ) : (
                searchCounts && (
                  <CategoriesChart
                    searchCounts={searchCounts?.filter(
                      (item) => item.label !== 'Requires review'
                    )}
                  />
                )
              )}
            </div>
          </div>

          <div className='grid gap-4 grid-cols-1 lg:grid-flow-col lg:grid-cols-auto'>
            <div className='border overflow-x-clip rounded-lg'>
              <Card
                title={'Latest deepfakes'}
                data={dashboardDeepfakes}
                name={'deepfake'}
                deepfakeLoading={deepfakeLoading}
              />
            </div>

            <div className={`border overflow-x-clip rounded-lg lg:max-w-md`}>
              <Card
                title={'Latest adult infringements'}
                data={dashboardVideos}
                name={'videos'}
                videosLoading={videosLoading}
              />
            </div>
          </div>

          <div className='w-full border rounded-lg'>
            <Card
              title={'Latest social content'}
              data={dashboardSocial}
              name={'social'}
              socialLoading={socialLoading}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default TrendingSection
