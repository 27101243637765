import { useStore } from 'zustand'
import ReviewedTabs from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewedTabs/ReviewedTabs.jsx'
import watchtowerStore from '../../watchtowerStore'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useFetchSearchSummary } from '@/pages/Home/hooks/useFetchSearchSummary'
import {
  useFetchSearches,
  useSetTabFromQuery
} from '@/pages/Watchtower/hooks/useWatchtowerHooks'
import { computeCounts, fetchSearches } from '../../utils'
import ActionDock from '@/components/ActionDock/ActionDock.jsx'
import { createTakedown } from '@/redux/Takedowns/actions.js'
import FilterTab from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/FilterTab.jsx'
import ReviewRequiredTabs from '@/pages/Watchtower/pages/Home/components/Tabs/FilterTab/ReviewRequiredTabs/ReviewRequiredTabs.jsx'
import Pagination from '@/components/Pagination/Pagination.jsx'
import { useRef } from 'react'
import { setIsNotDeepfake } from '@/redux/Watchtower/actions.js'

export default function Watchtower() {
  const {
    watchtowerData,
    setWatchtowerData,
    selectedTab,
    setSelectedTab,
    searchSummary,
    loading,
    selectedResults,
    setSelectedResults,
    threatLevel,
    selectedFilterTab,
    reviewRequiredData,
    selectedReviewRequiredTab,
    setReviewRequiredData,
    disableTakedownButton,
    sortConfig,
    setSortConfig
  } = useStore(watchtowerStore)
  const prevThreatLevel = useRef(threatLevel)
  const dispatch = useDispatch()
  // const [format, setFormat] = useState(null)
  // const [category, setCategory] = useState(null)
  const [, setSearchParams] = useSearchParams()
  const counts = computeCounts(searchSummary)

  const data =
    selectedFilterTab === 'results' ? watchtowerData : reviewRequiredData

  const tab =
    selectedFilterTab === 'results' ? selectedTab : selectedReviewRequiredTab

  const setData =
    selectedFilterTab === 'results' ? setWatchtowerData : setReviewRequiredData
  useFetchSearchSummary(false)
  useSetTabFromQuery(setSelectedTab)
  useFetchSearches(
    (page, tab) =>
      fetchSearches({
        dispatch,
        tab,
        page
      }),
    prevThreatLevel
  )
  const handlePageChange = (page) => {
    setData(tab, { currentPage: page })
    fetchSearches({
      dispatch,
      tab,
      page
    })
  }

  const handleTabChange = (tab) => {
    setSearchParams({ selectedTab: tab })
    setSelectedTab(tab)
    setSortConfig(
      sortConfig?.filter((item) => item.key === 'followers_sorting')
    )
  }
  //* Using for batch select mark for takedown.
  const handlePrimary = () => {
    const searchResultsIds = selectedResults.map(encodeURIComponent).join(',')
    let payload = {
      id: searchResultsIds,
      tab: selectedTab,
      key: 'array'
    }

    if (selectedResults.length > 1) {
      payload = {
        ...payload,
        key: 'array',
        tab: selectedTab
      }
    }
    dispatch(createTakedown({ payload }))
    setSelectedResults([])
  }

  //* Using for batch select not a deepfake.
  const handleTertiary = () => {
    const searchResultsIds = selectedResults.join(',')
    let payload = {
      ids: searchResultsIds
    }
    dispatch(setIsNotDeepfake({ payload }))
    setSelectedResults([])
  }

  return (
    <div className='w-full max-w-screen-xl md:px-16 px-6 pb-8'>
      <div className='flex justify-between items-center'>
        <div className=''>
          <p className='text-xl font-semibold text-foreground'>Watchtower</p>
        </div>
        {/* <div className=''>
          <ExportDialog
            category={category}
            setCategory={setCategory}
            format={format}
            setFormat={setFormat}
            disabled={
              watchtowerData[selectedTab]?.loading || counts[selectedTab] === 0
            }
          />
        </div> */}
      </div>
      <div className='w-full flex gap-4 mt-4 h-full'>
        <FilterTab
          results={
            <ReviewedTabs onTabChange={handleTabChange} counts={counts} />
          }
          review_area={<ReviewRequiredTabs />}
        />
      </div>
      <div className='fixed bottom-0 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <div
          className={`flex justify-center transition-all duration-300 mb-2 ${
            selectedResults.length > 0
              ? 'opacity-100 max-h-20'
              : 'opacity-0 max-h-0'
          }`}
        >
          <ActionDock
            dataLength={selectedResults?.length}
            primaryAction={{
              text: 'Mark for takedown',
              alertTitle: 'Mark for takedown',
              alertDescription:
                "Marking this for takedown will kick-off Loti's takedown service. Please be certain that this content is unwanted before proceeding.",
              alertOk: 'Continue',
              alertCancel: 'Cancel',
              handlePrimary: handlePrimary,
              disabled: disableTakedownButton
            }}
            tertiaryAction={{
              text: 'Not a deepfake',
              alertTitle: 'This is not a deepfake',
              alertDescription:
                "Are you sure you want to mark selected result(s) as not a deepfake? Please be certain because this action can't be undone.",
              alertOk: 'Continue',
              alertCancel: 'Cancel',
              handlePrimary: handleTertiary
            }}
            selectedTab={selectedTab}
          />
        </div>
        <Pagination
          currentPage={data[tab]?.currentPage}
          handlePageChange={handlePageChange}
          disableNext={data[tab]?.isLastPage || loading || !data[tab]?.data}
          disablePrev={
            data[tab]?.currentPage === 1 || loading || counts[tab] === 0
          }
          pageKeys={Object.keys(data[tab]?.pageKeys)}
          loading={data[tab]?.loading}
        />
      </div>
    </div>
  )
}
