import { useEffect, useState } from 'react'
import Error from '../../assets/error.png'
import { TriangleAlert } from 'lucide-react'
import ButtonWrapper from '../common/Wrappers/Button/ButtonWrapper'
import { cn } from '@/lib/utils'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import SocialIcon from '../common/ui/social-icon'
import FullImagePreview from './FullImagePreview'

export default function NsfwImageSection({
  hideSensitiveContent,
  imgFallback,
  img,
  intermediateFallbackImg,
  size = 'large',
  handleNavigation,
  id,
  category,
  title,
  customHeight,
  customWidth,
  canPreviewFullImage = false,
  imgPointer = false
}) {
  const [showImage, setShowImage] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [fallbackImageError, setFallbackImageError] = useState(false)
  const [intermediateFallbackImageError, setIntermediateFallbackImageError] =
    useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setShowImage(!hideSensitiveContent)
  }, [hideSensitiveContent])

  const handleImageError = () => {
    setImageError(true)
  }

  const handleIntermediateFallbackImageError = () => {
    setIntermediateFallbackImageError(true)
  }

  const handleFallbackImageError = () => {
    setFallbackImageError(true)
  }

  const handleImageLoad = () => {
    setLoading(false)
  }

  let imageSrc = img

  if (imageError && !intermediateFallbackImageError) {
    imageSrc = intermediateFallbackImg
  } else if (intermediateFallbackImageError && !fallbackImageError) {
    imageSrc = imgFallback
  } else if (fallbackImageError) {
    imageSrc = Error
  }

  const handleClick = (e) => {
    e.stopPropagation()
    setShowImage(!showImage)
  }

  return (
    <div
      className={cn(
        'overflow-hidden relative rounded-xl cursor-pointer bg-black/50 group',
        {
          'w-64 h-64 cursor-default': size === 'large',
          'w-16 h-16': size === 'small',
          'w-[120px] h-[200px]': size === 'medium',
          [`w-[${customWidth}] h-[${customHeight}] rounded-none`]:
            customHeight && customWidth
        }
      )}
    >
      <div className='w-full h-full relative'>
        {loading && !imageError && (
          <div className='w-full absolute flex justify-center items-center h-full bg-gradient-to-t from-black/50 to-transparent'>
            <SkeletonWrapper number={1} height='100%' width='100%' />
          </div>
        )}
        {canPreviewFullImage && category !== 'adult' ? (
          <FullImagePreview src={imageSrc} />
        ) : null}
        <img
          className={cn(
            'w-full h-full object-cover hover:brightness-100 transition-all duration-150',
            {
              'blur-2xl brightness-100': !showImage && category === 'adult',
              'cursor-pointer': imgPointer
            }
          )}
          src={imageSrc || Error}
          alt='img'
          onError={() => {
            if (!imageError) {
              handleImageError()
            } else if (!intermediateFallbackImageError) {
              handleIntermediateFallbackImageError()
            } else {
              handleFallbackImageError()
            }
          }}
          onClick={() => {
            handleNavigation && handleNavigation(id)
          }}
          onLoad={handleImageLoad}
        />
        {!loading && (
          <>
            {category === 'adult' && (
              <div
                className={`absolute top-1/2 right-1/2 transform translate-x-1/2 select-none -translate-y-1/2 ${showImage && 'opacity-0'}`}
              >
                <ButtonWrapper
                  className=''
                  icon={<TriangleAlert className='text-white' />}
                  variant='translucent'
                  size='icon'
                  onClick={(e) => {
                    handleClick(e)
                  }}
                />
              </div>
            )}
            {size === 'medium' && (
              <div className='w-full absolute bottom-0 flex flex-col items-start justify-end p-2 bg-gradient-to-t from-black to-transparent h-20 group-hover:h-24'>
                {category ? (
                  <div className='absolute bottom-2 left-2 flex items-center gap-2'>
                    <SocialIcon
                      className='w-5 h-5 opacity-80 group-hover:opacity-100'
                      platform={category}
                      fill='white'
                    />
                    <p className='text-xs line-clamp-1 text-wrap text-white opacity-0 group-hover:opacity-90 transition-opacity duration-300'>
                      {category === 'adult'
                        ? 'Sensitive Content'
                        : category.charAt(0).toUpperCase() + category.slice(1)}
                    </p>
                  </div>
                ) : null}
                <div className='absolute w-full left-0 bottom-[-100px] group-hover:bottom-7 p-2 text-white transition-all duration-300 ease-in-out'>
                  <p className='text-xs line-clamp-3 text-wrap'>{title}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
